.OrgUnitList {
  .area-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .area-title {
      position: sticky;
      top: 0px;
      background: #eee;
      z-index: 100;
      padding: 10px;
      font-weight: bold;
    }
  }

  .card-org-info {
    flex: 2;
    padding-bottom: 16px;
  }
  .no-results {
    text-align: center;
    padding: 50px;
  }

  .modal-title {
    width: 100%;
  }

  .form-check {
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-top: 8px;

    .form-check-label {
      padding-top: 4px;
    }
  }

  .NotFound {
    height: unset;
  }
}
