$impact-time: 2s;
$initial-time: 0.4s;

.PackageSentIndicator {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 300;
  .image-container {
    #LoadingCar {
      width: 400px;
      max-width: 70%;
      position: relative;
      right: 0px;
      top: -30px;
    }
    #Mail {
      width: 70px;
      max-width: 100px;
      position: relative;
      top: -30px;
    }
  }
  .caption-image {
    position: relative;
    top: -15px;
    font-size: 1.5rem;
    font-style: italic;
    color: var(--bs-primary);
  }

  @keyframes shadow {
    0% {
      transform: scale(0.99);
    }
    100% {
      transform: scale(1.02);
    }
  }

  #tire1 {
    visibility: hidden;
    animation: impact $impact-time ease-in-out forwards, spin 0.4s infinite;
    fill: red;
    transform-origin: 56.3% 84.5%;
    animation-delay: $initial-time, 1.5s;
  }

  #tire2 {
    visibility: hidden;
    transform-origin: 83.23% 84.8%;
    animation: impact $impact-time ease-in-out forwards, spin 0.4s infinite;
    background-color: red;
    animation-delay: $initial-time, 1.5s;
  }

  #housing,
  #hermes {
    animation: impact $impact-time ease-in-out forwards, bobbing 0.2s infinite ease-in-out forwards alternate;
    //animation: impact $impact-time infinite ease-in-out forwards;
    animation-delay: $initial-time, 2.6s;
  }

  #wind {
    animation: fadeBack 0.6s infinite ease forwards;
    animation-delay: 1.5s;
    opacity: 0;
  }

  #smoke {
    animation: bobbing 0.2s infinite ease-in-out forwards alternate;
    animation: fadeForward 0.4s infinite ease-in-out forwards alternate;
    animation-delay: 1.2s;
    opacity: 0;
  }

  #shadow {
    animation: shadow 0.2s infinite ease-in-out forwards alternate;
    transform-origin: right center;
    animation-delay: 2s;
  }

  #Mail {
    animation: moveForward 0.5s ease-in-out forwards;
    animation-delay: 0.3s;
  }
  #bodyoverlay {
    fill: white;
    fill-opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes bobbing {
  0% {
    transform: rotate(0) translateY(0);
  }
  50% {
    transform: rotate(0.5deg) translateY(0);
  }
  100% {
    transform: rotate(-0.2deg) translateY(5px);
  }
}

@keyframes impact {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(1deg) translateX(50px);
  }
  30% {
    transform: rotate(0deg) translateX(0px);
  }
  45% {
    transform: rotate(-35deg) translateY(260px) translateX(-360px);
  }
  60% {
    transform: rotate(-36deg) translateY(260px) translateX(-360px);
  }
  70% {
    transform: rotate(-37deg) translateY(260px) translateX(-360px);
  }
  92% {
    transform: rotate(-35deg) translateY(260px) translateX(-360px);
  }
  99% {
    visibility: hidden;
  }
  100% {
    transform: rotate(0deg) translateX(0px);
    visibility: visible;
  }
}

@keyframes jolt {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-50deg) translateX(0px);
  }
  100% {
    transform: rotate(0deg) translateX(0px);
  }
}

@keyframes fadeBack {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}

@keyframes fadeForward {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    transform: translateX(180px);
    opacity: 0;
  }
}

@keyframes moveForward {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(250px);
  }
  100% {
    transform: translateX(250px);
    opacity: 0;
  }
}
