.card {
  border-radius: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  background-color: rgb(254, 254, 254);
  z-index: 200;

  .card-body {
    padding: 10px;
  }
  .card-header {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .card-title {
      flex: 1;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0;
    }

    .badge {
      margin-left: 2px;
    }
  }
}
