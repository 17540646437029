.NotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  .truck-image {
    width: 100%;
    padding: 0 20%;
  }

  .caption-image {
    font-style: italic;
    color: #7b7b7b;
  }
}
