.BootstrapModal {
  &.modal-dialog {
    max-height: calc(100% - 3.5rem);
    overflow: hidden;
    display: flex;

    .modal-content {
      .modal-body {
        overflow: auto;
        max-height: calc(100vh - 15rem);
      }
    }
  }
}
