.OrgUnitCard {
  display: flex;
  cursor: pointer;
  margin: 2px 0;
  border: 1px solid lightgray;
  background: #fff;
  position: relative;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;

  &:hover {
    background-color: #f6f6f6;
  }

  &:active {
    background-color: #e5effa;
  }

  &.selected-card {
    background-color: #d0e6ff;
  }

  .title {
    font-size: 12px;
    line-height: 12px;
    margin-right: 0.7rem;
  }

  .links {
    a {
      padding: 10px;
      border-radius: 3rem;
      &:hover {
        background-color: #d0e6ff;
      }
    }

    .lma-only-link {
      margin-left: -25px;
    }
  }

  .status {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50px;

    a {
      font-size: 11px;
      height: 100%;

      &:hover {
        color: var(--bs-primary);
      }
    }

    span {
      font-size: 11px;
      line-height: 11px;
      background: #eee;
      padding: 2px;
      text-transform: uppercase;
      font-weight: bold;
      width: 75px;
      text-align: center;

      &.active {
        background: green;
        color: #fff;
      }
    }
  }
}
