@import "src/app_variables";

.HelpEntryRow {

  .HelpEntryIcon {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .HelpEntryName {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 10px 0;

    * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .HelpEntryDate {
    display: flex;
    align-items: center;
  }

  .highlighted {
    background-color: adjust-color($hermes-blue, $alpha: -0.7);
  }
}