.LocationModal {
  .modal-body {
    display: flex;
    flex-direction: column;
    .name-area-container {
      display: flex;
      flex-direction: row;

      .name-label-container {
        flex: 2;
      }
      .area-label-container {
        z-index: 500;
        flex: 1;
        margin-left: 10px;
      }
    }
    .name {
      margin-bottom: 8px;
    }
    .name-label {
      display: flex;
    }

    .notice {
      color: red;
      font-size: 12px;
      margin: 10px 0 10px 36px;
      display: none;

      &.invalid {
        display: block;
      }
    }
    .map {
      width: 100%;
      height: 400px;
    }

    .standort-error {
      display: block;
      margin-top: 8px;
    }
  }

  .modal-content {
    .form-check {
      display: flex;
      justify-content: flex-end;
      padding-top: 0.6rem;
    }

    .alert {
      margin-top: 16px;
    }
  }

  .disabled-button {
    opacity: 0.65;
    cursor: default !important;
  }
  #geocoder {
    margin-top: 4px;
  }

  .address-error {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
