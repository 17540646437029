.CopyFromOrgModal {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #ced4da;

    .title {
      h2 {
        font-size: 22px;
        margin: 0;
      }
    }

    .section {
      display: flex;
      align-items: center;
    }
  }
}
