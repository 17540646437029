.UserRow {
  .td-baseline {
    vertical-align: baseline;
  }

  .deactivated {
    color: rgb(153, 153, 153);
  }

  .more-menu-button:hover {
    color: white;
  }
}
