$form-feedback-valid-color: #ced4da;

$primary: #0091cd;
$secondary: #7b7b7b;
$link-color: $primary;
$gray: #eceff1;
$muted: #333334;

:root {
  --bs-accent: #edbc7c;
  --bs-accent-light: #f4d0a1;
  --bs-sidebar-prod: $primary;
  --bs-sidebar-staging: #fc9701;
  --bs-sidebar-local: #6c55ff;
}

html {
  font-size: 14px;
}

@import "/node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "/node_modules/react-datepicker/dist/react-datepicker.css";
@import "/node_modules/bootstrap/scss/bootstrap";
@import "./modules/scss/inputs.scss";
@import "./modules/scss/tables.scss";
@import "./modules/scss/cards.scss";
@import "./modules/scss/modals.scss";
@import "src/app_variables";
@import "/node_modules/@hermesgermany/last-mile-components/dist/style.css";

.App {
  display: flex;
  flex: 1;
  height: 100vh;

  #reach-router {
    display: flex;
    flex: 1;

    .Main {
      display: flex;
      flex: 1;
      background: #fafafa;
      overflow-y: hidden;
      width: 100%;
      height: 100vh;
      max-height: 100vh;

      .VerticalSeparator {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 1px;
        margin: 11vh 15px;
      }

      .container-fluid {
        height: 100vh;
      }
    }
  }
}

a,
button,
.btn {
  &.focus,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.ActionButtons {
  .btn,
  button {
    margin: 3px;
  }
}

.btn {
  &.btn-primary,
  &.btn-secondary,
  &.btn-outline-primary {
    color: white;
  }
}

.KeyValuePair {
  margin: 10px 0;
  padding: 10px 0;

  .key {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    color: #999;
    font-weight: bold;
  }
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row.inner-app {
  height: 100%;
  display: flex;
}

.card-header-button {
  margin-top: -4px;
  margin-bottom: -4px;
}

.cursor-pointer {
  cursor: pointer;
}

.introjs-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: initial;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  text-shadow: none;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 145 205 / 50%);
    background-color: initial;
  }
}

.introjs-prevbutton {
  font-weight: 400;
  color: $hermes-blue;
  text-decoration: none;

  &.introjs-disabled {
    color: #6c757d;
    pointer-events: none;
  }

  &:focus {
    color: $hermes-blue;
    border-color: transparent;
  }

  &:hover {
    color: #005b81;
    background-color: initial;
    border-color: transparent;
    text-decoration: underline;
  }

  &:active {
    color: $hermes-blue-active;
    background-color: initial;
    border-color: transparent;
    text-decoration: underline;
  }
}

.introjs-nextbutton,
.introjs-donebutton {
  color: #fff;
  background-color: $hermes-blue;
  border-color: $hermes-blue;

  &:focus {
    color: #fff;
    background-color: $hermes-blue;
    border-color: $hermes-blue;
  }

  &:hover {
    color: #fff;
    background-color: $hermes-blue-hover;
    border-color: $hermes-blue-hover;
  }

  &:active {
    color: #fff;
    background-color: $hermes-blue-active;
    border-color: $hermes-blue-active;
  }
}

.introjs-tooltip {
  max-width: 500px !important;

  .introjs-tooltiptext {
    max-width: 500px !important;
  }
}

.introjs-hints {
  .introjs-hint {
    z-index: 9999;
    margin-left: 21px;
    margin-top: -4px;
  }
}

.modal-header {
  align-items: flex-start;
}

// ::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 6px;
//   margin: 2px;
//   background-color: white;
//   border: 1px solid rgb(225, 225, 225);
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgba(0, 0, 0, 0.31);
//   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
//   box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }

.cursor-pointer {
  cursor: pointer;
}
