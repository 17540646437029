.ActionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;

  .confirm-deletion {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    align-items: center;
    font-size: 12px;
    overflow: hidden;
    .confirm-deletion-slide {
      position: relative;
      display: flex;
      left: 40px;
      animation: slide 0.1s forwards;
    }
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
