.ConfirmationModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 7rem;

  .modal-content {
    .confirmation-buttons {
      button {
        margin: 0.5rem;
      }
    }
    padding-top: 2rem;
  }
}
