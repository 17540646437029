.CockpitSettings {
  .header {
    display: flex;
    justify-content: space-between;
    
    .title {
      h2 {
        font-size: 22px;
        margin: 0;
      }
      .badge {
        margin-right: 5px;
      }
    }

    .section {
      display: flex;
      align-items: center;
    }
  }
  h3 {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 25px;
  }
  .nav-link {
    cursor: pointer;
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .nav-tabs {
    position: relative;
    border-bottom: 1px solid #dee2e6;
    z-index: 1000;
  }

  .tab-content {
    .tab-content-card {
      border-top: 0;
    }
  }

  .card {
    position: static;
    margin-bottom: 20px;
  }

  .rs-input {
    max-width: 35px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .teze-input {
    max-width: 65px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .teze-input-button {
    margin-left: 5px;
  }

  .col-form-label {
    text-align: right;
  }

  .map {
    width: 100%;
    height: 400px;
  }
  .app-link-container {
    .zsb-cockpit-link {
      padding: 4px;
      margin-left: 5px;
      margin-right: 3px;
      width: 40px;
      height: 40px;
    }

    .lma-link {
      padding: 4px;
      margin-left: 5px;
      margin-right: 3px;
      width: 40px;
      height: 40px;
    }
  }

  .device-mgmt-link {
    padding: 4px;
    width: 40px;
    height: 40px;
  }
  .depot-cutoff {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .depot-org-unit {
    display: flex;
    margin: 2px 0;
    background: #fff;
    position: relative;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
  }
}
