.LocationCard {
  display: flex;
  flex-direction: column;
  background-color: #f8f7f7;
  border: 1px solid rgb(220, 220, 220);
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;

  &.hovered {
    background-color: #dfdede;
  }

  &.selected {
    background-color: var(--bs-accent);

    &.hovered {
      background-color: var(--bs-accent-light);
    }
  }

  .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-right: 9px;

    .name {
      flex: 1;
      .location-header__name__label {
        margin-right: 8px;
      }
    }
    button {
      margin-left: 0.5rem;
    }
  }
  .id {
    flex: 1;
    color: grey;
  }
}

.OrgUnitListModal {
  width: 60vw;
  max-width: 50vw;
  max-height: 30vh;
  height: 30vh;

  .modal-content {
    padding: 0px;
  }
  .OrgUnitList {
    max-height: 80vh;
    height: 80vh;
  }
}
