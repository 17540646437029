.MinivanLoadingIndicator {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #c7e2ffaf;

  z-index: 300;
  .image-container {
    #LoadingCar {
      width: 800px;
      max-width: 70%;
    }
    position: relative;
    top: -5vh;
    left: 10vh;
  }
  .caption-image {
    position: relative;
    top: 0vh;
    font-size: 1.5rem;
    font-style: italic;
    color: var(--bs-primary);
  }

  @keyframes shadow {
    0% {
      transform: scale(0.99);
    }
    100% {
      transform: scale(1.02);
    }
  }

  #tire1 {
    width: 210px;
    height: 210px;
    position: relative;
    animation: spin 0.4s infinite;
    fill: red;
    transform-origin: 56.3% 84.5%;
  }

  #tire2 {
    transform-origin: 83.23% 84.8%;
    animation: spin 0.4s infinite;
    background-color: red;
  }

  #housing,
  #hermes {
    animation: bobbing 0.2s infinite ease-in-out forwards alternate;
  }

  #wind {
    animation: fadeBack 0.6s infinite ease forwards;
  }

  #smoke {
    animation: bobbing 0.2s infinite ease-in-out forwards alternate;
    animation: fadeForward 0.4s infinite ease-in-out forwards alternate;
  }

  #shadow {
    animation: shadow 0.2s infinite ease-in-out forwards alternate;
    transform-origin: right center;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes bobbing {
  0% {
    transform: rotate(0) translateY(0);
  }
  50% {
    transform: rotate(0.5deg) translateY(0);
  }
  100% {
    transform: rotate(-0.2deg) translateY(5px);
  }
}

@keyframes fadeBack {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}

@keyframes fadeForward {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    transform: translateX(180px);
    opacity: 0;
  }
}
