.SearchInput {
  position: relative;
  display: flex;
  flex: 1;

  .inner-addon {
    position: relative;
    width: 100%;

    .btn-clear-search {
      cursor: pointer;
      position: absolute;
      top: 2px;
      color: var(--bs-secondary);
      background-color: transparent;
      border: 0px;

      &:hover {
        color: var(--bs-primary);
      }
    }

    &.left-addon .btn-clear-search {
      left: 0px;
    }
    &.right-addon .btn-clear-search {
      right: 0px;
    }

    &.left-addon input {
      padding-left: 30px;
    }
    &.right-addon input {
      padding-right: 30px;
    }
  }
}
