.MapOverview {
  height: 100%;
  width: 100%;
}

.LocationNamePopup {
  .mapboxgl-popup-content {
    padding: 5px 10px;
  }
}
