// Colors
$hermes-blue: #0091cd;
$hermes-blue-hover: #0076a7;
$hermes-blue-active: #006d9a;
$link-color: $hermes-blue; // should we use the default link color #0645AD?

$blue-selected-row: rgba(0, 145, 205, 0.2);

$spacer: 10px;
$spacer-sm: 5px;

$border: 1px solid #e5e5e5;

$table-margin: calc(1.5rem + 1.25em);
$table-width: calc(100% - 1.5rem - 1.25em);

// TODO: This should work from react 17 onwards to import variables inside our tsx files
// Unfortunately it doensn't work in our setup but it would be cool to fix it/use it
/* :export {
  hermes-blue: $hermes-blue;
  blue-selected-row: $blue-selected-row;
}  */
