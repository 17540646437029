@import "src/app_variables";

.ModalHeader {
  padding-left: calc(4 * $spacer);

  .title {
    font-weight: 600;
    font-size: 16px;
  }
}
