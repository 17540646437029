.LocationList {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow-y: auto;

  &:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    right: -5px;
    z-index: 100;
  }

  .filters-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .header {
    display: inline-block;
  }

  .add-location-btn {
    background-color: var(--bs-primary);
    color: white;
    border: 0px;
    border-radius: 6px;
    margin-left: 1rem;
    margin-top: 0.1rem;
    width: 35px;
    height: 35px;
  }

  .add-location-btn:hover {
    background-color: white;
    color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
  }

  .list {
    overflow-y: auto;
    height: 100%;
  }
}
