.AreaDropdown {
  flex: 1;
  font-size: 12px;

  & > &:focus {
    outline: none;
  }

  .dropdown-toggle {
    flex: 1;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    //text-align: start;
  }
  .reset-field {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    &.hidden {
      color: transparent;
    }
  }

  .dropdown-menu {
    max-height: 40vh;
    overflow-y: auto;
    width: 100%;
  }
}
